import React from 'react'

export const SiteFooter = () => {
  return <footer className="footer negative">
      <div className="container">
        <div className="row">
          <div className="col-6">
            Vassoy.org er drevet av Vassøy beboerforening. En forening som arbeider for øyas beste.
          </div>
          <div className="col-3">
            <h3>Kontakt</h3>
            <a href="mailto:styret@vassoy.org">Styret</a>
          </div>
        </div>
      </div>
    </footer>
}